// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-press-index-page-js": () => import("/app1/press-site/ci-hub/.workspace/press-site/packages/press-site-gatsby/src/templates/press-index-page.js" /* webpackChunkName: "component---src-templates-press-index-page-js" */),
  "component---src-templates-press-search-page-js": () => import("/app1/press-site/ci-hub/.workspace/press-site/packages/press-site-gatsby/src/templates/press-search-page.js" /* webpackChunkName: "component---src-templates-press-search-page-js" */),
  "component---src-templates-press-post-js": () => import("/app1/press-site/ci-hub/.workspace/press-site/packages/press-site-gatsby/src/templates/press-post.js" /* webpackChunkName: "component---src-templates-press-post-js" */),
  "component---src-pages-404-js": () => import("/app1/press-site/ci-hub/.workspace/press-site/packages/press-site-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/app1/press-site/ci-hub/.workspace/press-site/packages/press-site-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-index-js": () => import("/app1/press-site/ci-hub/.workspace/press-site/packages/press-site-gatsby/src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */)
}

