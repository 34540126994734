module.exports = [{
      plugin: require('/app1/press-site/ci-hub/.workspace/press-site/node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore","serialize":{"space":0,"isJSON":true,"unsafe":false}},
    },{
      plugin: require('/app1/press-site/ci-hub/.workspace/press-site/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/app1/press-site/ci-hub/.workspace/press-site/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/app1/press-site/ci-hub/.workspace/press-site/packages/press-site-gatsby/src/cms/cms.js"},
    },{
      plugin: require('/app1/press-site/ci-hub/.workspace/press-site/packages/press-site-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
