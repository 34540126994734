import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import createStore from './createStore';

const ReduxProvider = ({ element }) => (
  <Provider store={createStore()}>
    {element}
  </Provider>
);

ReduxProvider.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ReduxProvider;
