import {
  SEARCH_FILTER_KEYWORD,
  SEARCH_REQUESTED,
  SEARCH_SUCCEEDED,
  SEARCH_FAILED,
} from './action';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_FILTER_KEYWORD: {
      return {
        ...state,
        keyword: action.payload,
      };
    }
    case SEARCH_REQUESTED: {
      return Object.assign({}, {
        ...state,
        isSearchRequested: true,
      });
    }
    case SEARCH_SUCCEEDED: {
      return Object.assign({}, {
        ...state,
        response: action.payload,
        isSearchRequested: false,
      });
    }
    case SEARCH_FAILED: {
      return {
        ...state,
        error: action.payload,
        isSearchRequested: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
